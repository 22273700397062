import * as React from "react";
import Layout from "../components/layout/layout";
import styeld from "styled-components";
import About1 from "../components/about/about1";
import About2 from "../components/about/about2";
import About3 from "../components/about/about3";
import About4 from "../components/about/about4";
import About5 from "../components/about/about5";
import About6 from "../components/about/about6";
import { Helmet } from "react-helmet";
const Containar = styeld.div`
`;

const tag_title = "소개 - 샘랩";
const tag_description = "샘랩의 소개 페이지 입니다.";
// markup
const AboutUs = () => {
  return (
    <Layout>
       <Helmet>
        <title>{tag_title}</title>
        <meta name="title" content={tag_title} />
        <meta name="description" content={tag_description} />
        <meta property="og:title" content={tag_title} />
        <meta property="og:description" content={tag_description} />
        <meta property="og:url" content="https://samlab.co.kr/aboutus" />
        <meta property="twitter:title" content={tag_title} />
        <meta property="twitter:description" content={tag_description} />
        <meta property="twitter:url" content="https://samlab.co.kr/aboutus" />
      </Helmet>
      <About1 />
      <About2 />
      <About3 />
      <About4 />
      <About5 />
      <About6 />
    </Layout>
  );
};

export default AboutUs;
