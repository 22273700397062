import { useRef, useEffect, useCallback } from "react";

const useScrollCenter = (direction = "center", duration = 1, delay = 0) => {
  const element = useRef();

  const handleClipPath = (name) => {
    switch (name) {
      case "center":
        return "inset(5% 5% 5% 5% round 200px)";
      case "center-none":
        return "inset(100% 100% 100% 100% round 200px)";
      case "left":
        return "inset(5% 100% 100% 5% round 200px)";
      case "up":
        return "inset(100% 5% 5% 5% round 200px)";
      default:
        return;
    }
  };

  const onScroll = useCallback(
    ([entry]) => {
      const { current } = element;
      if (entry.isIntersecting) {
        current.style.transitionProperty = "transform, clip-path";
        current.style.transitionDuration = `${duration * 1.2}s, ${duration}s`;
        current.style.transitionTimingFunction =
          "cubic-bezier(.675,.005,.31,1.005)";
        current.style.transitionDelay = `${delay}s`;
        current.style.transform = "scale(1)";
        current.style.clipPath = "inset(0 0 0 0)";
        current.style.borderRadius = "0";
      }
    },
    [delay, duration]
  );

  useEffect(() => {
    let observer;

    if (element.current) {
      observer = new IntersectionObserver(onScroll, { threshold: 0.7 });
      observer.observe(element.current.parentNode);
    }

    return () => observer && observer.disconnect();
  }, [onScroll]);

  return {
    ref: element,
    style: {
      transform: "scale(0.2)",
      clipPath: handleClipPath(direction),
      borderRadius: "100px%",
    },
  };
};

export default useScrollCenter;
