import * as React from "react";
import styled from "styled-components";
import img1 from "../../images/aboutus/about3-1.png";
import img2 from "../../images/aboutus/about3-2.png";
import useScrollCenter from "../useScrollCenter";
import useScrollFadeIn from "../useScrollFadein";
import useScrollMove from "../useScrollMove";

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .title {
    position: absolute;
    top: 20vw;
    transform: rotate(90deg);
    display: flex;
    font-family: "Pretendard-ExtraBold";
    font-size: 4vw;
    opacity: 0.8;
    left: -2vw;
    span {
      color: #222222;
    }
  }
  .main {
    width: 100%;
    margin: 10vw 0 10vw 19%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    .mainWrapper {
      /* display: flex; */
      width: 100%;
      .main-img {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
    img {
      :first-child {
        width: 30vw;
      }
      :last-child {
        width: 50vw;
        height: fit-content;
      }
    }

    .main-title {
      font-family: "Pretendard-ExtraBold";
      margin-top: 1.5vw;
      font-size: 2.6vw; //60px
      white-space: pre-wrap;
      line-height: 1.2;
    }
    .main-sub {
      font-family: "Pretendard-Medium";
      color: #555555;
      font-size: 1.25vw; //24px
      margin: 1.8vw 0 3.6vw;
    }
  }
  @media screen and (max-width: 1020px) {
    .title {
      top: 24vw;
      left: -5vw;
    }
    .main {
      img {
        :first-child {
          width: 34vw;
        }
      }
      margin: 10vw 0 10vw 17%;
      .main-title {
        font-size: 3.4vw;
      }
      .main-sub {
        font-size: 1.8vw;
      }
    }
  }
  @media screen and (max-width: 460px) {
    .main {
      margin: 0;

      .mainWrapper {
        overflow-x: hidden;
        margin-bottom: 15vw;
        .main-img {
          flex-direction: column;
        }
      }
      img {
        :first-child {
          width: 70vw;
        }
        :last-child {
          width: 95%;
          margin-top: 3vw;
          height: fit-content;
          object-fit: contain;
        }
      }

      .main-title {
        margin-left: 5%;
        margin-top: 3vw;
        font-size: 7vw; //60px
      }
      .main-sub {
        font-size: 4.8vw;
        width: 84%;
        margin: 4vw 0 20vw;
      }
    }
    .title {
      top: 25vw;
      left: -35vw;
      font-size: 10.5vw; //60px
      width: 110vw;
    }
  }
`;
function About3() {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
  };
  const moveAnimate = {
    0: useScrollMove("up", 1, 0.2),
  };
  const centerAnimate = {
    0: useScrollCenter("left", 1.4, 0.2),
    1: useScrollCenter("left", 1.4, 0.6),
  };
  const sub =
    "가치있는 일상을 위해 샘랩의 기술력을 한 곳에 모았습니다.\n더 가치 있는 일에 집중하도록\n체계적이고 혁신적인 솔루션을 제공합니다.";
  return (
    <Container>
      <div className="title">
        <span {...moveAnimate[0]}>NO MORE CHORES</span>
      </div>
      <div className="main">
        <div className="mainWrapper">
          <div className="main-img">
            <img {...centerAnimate[0]} src={img1} alt="img" />
            <img {...centerAnimate[1]} src={img2} alt="img" />
          </div>
          <div className="main-title">
            <span {...animation[0]}>무인화 시스템</span>
            <p {...animation[1]} className="main-sub">
              {sub}
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default About3;
