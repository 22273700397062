import * as React from "react";
import styled from "styled-components";
import img from "../../images/aboutus/about5.png";
import useScrollFadeIn from "../useScrollFadein";

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .main {
    width: 100%;
    margin: 10vw 0 10vw 19%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .mainWrapper {
      /* display: flex; */
      width: 100%;
      .main-img {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
    img {
      width: 40vw;
      height: fit-content;
    }

    .main-title {
      font-family: "Pretendard-ExtraBold";
      margin-top: 1.5vw;
      font-size: 2.6vw; //60px
      white-space: pre-wrap;
      line-height: 1.2;
    }
    .main-sub {
      font-family: "Pretendard-Medium";
      color: #555555;
      font-size: 1.25vw; //24px
      margin: 1.8vw 0 3.6vw;
    }
  }
  @media screen and (max-width: 1020px) {
    .main {
      margin: 10vw 0 10vw 10%;
      .main-title {
        font-size: 3.4vw;
      }
      .main-sub {
        font-size: 1.8vw;
      }
    }
  }
  @media screen and (max-width: 460px) {
    .main {
      align-items: flex-end;
      flex-direction: column-reverse;
      margin: 20vw 0 0 0;
      .main-img {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
      img {
        width: 100%;
        height: 55vw;
        object-fit: cover;
      }
      .main-title {
        width: 95%;
        margin-left: 5%;
        margin-top: 4vw;
        font-size: 7vw; //60px
      }
      .main-sub {
        font-size: 4.8vw;
        width: 57vw;
        margin: 4vw 0 40vw;
      }
    }
    .title {
      top: 4vw;
      left: -44vw;
      font-size: 10.5vw; //60px
      width: 110vw;
    }
  }
`;

function About5() {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
  };

  const title = "모두가 더 나은 삶을\n살 수 있도록 도와드리겠습니다.";
  return (
    <Container>
      <div className="main">
        <div className="main-title">
          <span {...animation[0]}>{title}</span>
          <p {...animation[1]} className="main-sub">
            '대세' 에 만족하지 않습니다. 샘랩의 '혁신'을 경험해 보세요.
          </p>
        </div>
        <div className="main-img">
          <img src={img} alt="img" />
        </div>
      </div>
    </Container>
  );
}

export default About5;
