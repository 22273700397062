import * as React from "react";
import styled from "styled-components";
import useScrollFadeIn from "../useScrollFadein";
import Accordion from "./accordion";

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  .title {
    margin: 0 19%;
    font-family: "Pretendard-ExtraBold";
    font-size: 3.1vw;
    margin-bottom: 4vw;
  }
  .main {
    margin: 0 19% 10vw;
  }
  .infos {
    margin-bottom: 1vw;
  }
  .history-sub {
    display: flex;
    p {
      :first-child {
        width: 10%;
      }
      :last-child {
        font-family: "Pretendard-Medium";
      }
    }
  }
  @media screen and (max-width: 1020px) {
    .title {
      margin: 0 10%;
      font-size: 4vw;
      margin-bottom: 5.6vw;
    }
    .main {
      margin: 0 10% 10vw;
    }
  }
  @media screen and (max-width: 460px) {
    .title {
      margin: 0 5%;
      font-size: 7vw;
      margin-bottom: 7vw;
    }
    .main {
      margin: 0 5% 30vw;
    }
    .infos {
      justify-content: space-between;
      margin-bottom: 5vw !important;
    }
    .white-space {
      white-space: pre-wrap;
    }
    .history-sub {
      display: flex;
      font-size: 14px;
      p {
        :first-child {
          width: 25%;
        }
      }
    }
  }
`;
function About6() {
  const contents = (
    <>
      <div className="history-sub">
        <p className="infos">4</p>
        <p>'렛미업' 사업 기획</p>
      </div>
      <div className="history-sub">
        <p className="infos">1</p>
        <p>샘랩(주) 기업부설연구소설립</p>
      </div>
    </>
  );
  const sub =
    "스터디카페 시공 특허 2개 출원/\n직영점개설(4개)/ 가맹점 60호점 가맹 계약";
  const contents2 = (
    <>
      <div className="history-sub">
        <p className="infos">12</p>
        <p>공간샘스터디오피스사업개시</p>
      </div>
      <div className="history-sub">
        <p className="infos">8~11</p>
        <p>열화상카메라키오스크개발</p>
      </div>
      <div className="history-sub">
        <p className="infos">8~11</p>
        <p>밴처기업인증(기보기술평가보증기업)</p>
      </div>
      <div className="history-sub">
        <p className="infos">5~7</p>
        <p className="white-space">{sub}</p>
      </div>
      <div className="history-sub">
        <p className="infos" style={{ display: "flex" }}>
          2
        </p>
        <p>무인키오스크개발완료/ 특허출원신청</p>
      </div>
    </>
  );
  const contents3 = (
    <>
      <div className="history-sub">
        <p className="infos">-</p>
        <p>스터디카페가맹사업개시 (상호: 공간샘)</p>
      </div>
      <div className="history-sub">
        <p className="infos" style={{ display: "flex" }}>
          -
        </p>
        <p>회사설립(자본금2백만원)</p>
      </div>
    </>
  );
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
  };
  return (
    <Container>
      <div className="title">
        <span {...animation[0]}>HISTORY</span>
      </div>
      <div className="main">
        <Accordion title="2021" contents={contents} />
        <Accordion title="2020" contents={contents2} />
        <Accordion title="2018" contents={contents3} />
      </div>
    </Container>
  );
}

export default About6;
