import * as React from "react";
import styled from "styled-components";
import icon from "../../images/aboutus/plus.svg";
import useScrollFadeIn from "../useScrollFadein";

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #000;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 2vw;
  height: 32px;
  margin: 2.5vw 0;
  font-family: "Pretendard-ExtraBold";
  :hover {
    margin-left: 0.3vw;
  }
  transition: all 0.1s ease-in !important;
  @media screen and (max-width: 1020px) {
    font-size: 3vw;
  }
  @media screen and (max-width: 460px) {
    font-size: 7vw;
    margin: 5vw 0;
  }
`;

const Button = styled.button`
  right: 2vw;
  background-color: transparent;
  border: none;
  position: absolute;
  cursor: pointer;
  img {
    width: 1.1vw;
  }
  @media screen and (max-width: 460px) {
    img {
      width: 5vw;
    }
  }
`;

const ContentsWrapper = styled.div`
  height: 0;
  width: inherit;
  padding: 0 2vw 0 8vw;
  overflow: hidden;
  background-color: #fff;
  transition: height 0.35s ease;
  @media screen and (max-width: 460px) {
    padding: 0;
  }
`;

const Contents = styled.div`
  padding: 0.1px;
  color: #555;
`;

function Accordion(props) {
  const parentRef = React.useRef(null);
  const childRef = React.useRef(null);
  const [isCollapse, setIsCollapse] = React.useState(false);

  const handleButtonClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      if (parentRef.current === null || childRef.current === null) {
        return;
      }
      if (parentRef.current.clientHeight > 0) {
        parentRef.current.style.height = "0";
        parentRef.current.style.background = "white";
      } else {
        parentRef.current.style.height = `${
          childRef.current.clientHeight + 30
        }px`;
        parentRef.current.style.background = "white";
      }
      setIsCollapse(!isCollapse);
    },
    [isCollapse]
  );

  const parentRefHeight = parentRef.current?.style.height ?? "0px";
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
  };
  return (
    <Container>
      <Header {...animation[0]} onClick={handleButtonClick}>
        {props.title}
        <Button
          style={{
            transform:
              parentRefHeight === "0px" ? "rotate(0deg)" : "rotate(-45deg)",
            transition: "transform 0.2s ease-in-out",
          }}
        >
          <img src={icon} alt="toggle-button" />
        </Button>
      </Header>
      <ContentsWrapper ref={parentRef}>
        <Contents ref={childRef}>{props.contents}</Contents>
      </ContentsWrapper>
    </Container>
  );
}

export default React.memo(Accordion);
