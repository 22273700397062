import * as React from "react";
import styled from "styled-components";
import img from "../../images/aboutus/about1.png";
import useScrollFadeIn from "../useScrollFadein";

const Container = styled.div`
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    height: 100vh;
    width: 100%;
    z-index: -99;
    object-fit: cover;
  }
  .main {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .main-title {
      margin-left: 19%;
      font-family: "Pretendard-ExtraBold";
      font-size: 4.2vw; //60px
      white-space: pre-wrap;
      line-height: 1.2;
      color: #fff;
      display: flex;
      flex-direction: column;
    }
  }
  @media screen and (max-width: 1020px) {
    .main {
      .main-title {
        margin-left: 7%;
        font-size: 7vw;
      }
    }
  }
  @media screen and (max-width: 460px) {
    img {
      height: 100vh;
      width: 100%;
      object-fit: cover;
      object-position: 40% 100%;
    }
    .main {
      .main-title {
        margin-left: 5%;
        font-size: 10.5vw; //60px
      }
    }
  }
`;

function About1() {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
  };
  return (
    <Container>
      <img src={img} alt="img" />
      <div className="main">
        <div className="main-title">
          <span {...animation[0]}>일상이</span>
          <span {...animation[1]}>가치있는 일로</span>
          <span {...animation[2]}>가득 차도록</span>
        </div>
      </div>
    </Container>
  );
}

export default About1;
