import * as React from "react";
import styled from "styled-components";
import img from "../../images/aboutus/about2.png";
import useScrollCenter from "../useScrollCenter";
import useScrollFadeIn from "../useScrollFadein";
import useScrollMove from "../useScrollMove";

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .title {
    position: absolute;
    top: 2.3vw;
    transform: rotate(90deg);
    display: flex;
    font-family: "Pretendard-ExtraBold";
    font-size: 4vw;
    opacity: 0.8;
    right: 28vw;
    span {
      :first-child {
        color: #fffdfd;
        margin: 0 2vw;
      }
      :last-child {
        color: #222222;
      }
    }
  }
  .main {
    width: 100%;
    margin: -4vw 19%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    .mainWrapper {
      width: 26vw;
      img {
        width: 26vw;
      }
    }

    .main-title {
      font-family: "Pretendard-ExtraBold";
      margin-top: 1.5vw;
      font-size: 2.4vw; //60px
      white-space: pre-wrap;
      line-height: 1.2;
      display: flex;
      flex-direction: column;
    }
    .main-sub {
      font-family: "Pretendard-Medium";
      color: #555555;
      font-size: 1.25vw; //24px
      margin: 1.8vw 0 3.6vw;
    }
  }
  @media screen and (max-width: 1020px) {
    .main {
      .main-title {
        font-size: 3.4vw;
      }
      .main-sub {
        font-size: 1.8vw;
      }
    }
  }
  @media screen and (max-width: 460px) {
    .main {
      width: 100%;
      margin: -14vw 0 0;
      .mainWrapper {
        width: 78vw;
        img {
          width: 78vw;
        }
      }

      .main-title {
        margin-top: 4vw;
        font-size: 7vw; //60px
      }
      .main-sub {
        font-size: 4.8vw;
        width: 70vw;
        margin: 4vw 0 40vw;
      }
    }
    .title {
      top: 9vw;
      left: -44vw;
      font-size: 10.5vw; //60px
      width: 110vw;
    }
  }
`;

function About2() {
  const animation = {
    0: useScrollFadeIn("up", 1.4, 0.5),
    1: useScrollFadeIn("up", 1.4, 0.6),
  };
  const moveAnimate = {
    0: useScrollMove("up", 1, 0.2),
    1: useScrollMove("up", 1, 0.2),
  };
  const centerAnimate = {
    0: useScrollCenter("center", 1.4, 0.2),
  };
  return (
    <Container>
      <div className="title">
        <span {...moveAnimate[0]}>ONLINE</span>
        <span {...moveAnimate[1]}>TO OFFLINE</span>
      </div>
      <div className="main">
        <div className="mainWrapper">
          <div style={{ borderRadius: "16px" }}>
            <img {...centerAnimate[0]} src={img} />
          </div>
          <div className="main-title">
            <span {...animation[0]}>비대면 학습 멘토링 서비스</span>
            <p {...animation[1]} className="main-sub">
              어디에서든 최고의 교육 컨설팅으로 더 나은 미래를 준비하세요.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default About2;
