import * as React from "react";
import styled from "styled-components";
import img1 from "../../images/aboutus/about4-1.png";
import img2 from "../../images/aboutus/about4-2.png";
import img3 from "../../images/aboutus/about4-3.png";
import img4 from "../../images/aboutus/about4-4.png";
import useScrollCenter from "../useScrollCenter";
import useScrollFadeIn from "../useScrollFadein";
import useScrollMove from "../useScrollMove";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title {
    display: flex;
    font-family: "Pretendard-ExtraBold";
    font-size: 4vw;
    opacity: 0.8;
  span {
      color: #222222;
    }
  }
  .main {
    width: 100%;
    margin: 2.5vw auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #f9f9f9;
    .mainWrapper {
      /* display: flex; */
      width: 100%;
      .main-img {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        img {
          width: 14.6vw;
          margin: 0 0.5vw;
        }
      }
    }

    .main-title {
      font-family: "Pretendard-ExtraBold";
      margin-top: 2.5vw;
      font-size: 2.6vw; //60px
      white-space: pre-wrap;
      line-height: 1.2;
      text-align: center;
    }
    .main-sub {
      font-family: "Pretendard-Medium";
      color: #555555;
      font-size: 1.25vw; //24px
      margin: 1.4vw 0 10vw;
    }
  }
  @media screen and (max-width: 1020px) {
    .main {
      .mainWrapper {
        .main-img {
          img {
            width: 19vw;
          }
        }
      }
      .main-title {
        font-size: 3.4vw;
      }
      .main-sub {
        font-size: 1.8vw;
      }
    }
  }
  @media screen and (max-width: 460px) {
    .main {
      margin: 10vw auto;

      .mainWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .main-img {
          flex-wrap: wrap;
          img {
            width: 32vw;
            margin: 0 1.5vw 3vw;
          }
        }
      }

      .main-title {
        margin-top: 4vw;
        font-size: 7vw; //60px
      }
      .main-sub {
        font-size: 4.8vw;
        width: 72vw;
        margin: 6vw 0 30vw;
      }
    }
    .title {
      font-size: 10.5vw; //60px
      line-height: 1.2;
      white-space: pre-wrap;
      text-align: center;
    }
  }
`;
function About4() {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
  };
  const moveAnimate = {
    0: useScrollMove("left", 1, 0.2),
  };
  const centerAnimate = {
    0: useScrollCenter("up", 1.4, 0.2),
    1: useScrollCenter("up", 1.4, 0.6),
    2: useScrollCenter("up", 1.4, 1),
    3: useScrollCenter("up", 1.4, 1.4),
  };

  const sub =
    "샘랩은 학습, 문화, 취미 거주 공간까지 공간과 서비스를 기획합니다.";
  const title = "SPACE\nFOR EVERYONE";
  const sub1 = "공간에 대한\n모든 서비스를 준비합니다";
  return (
    <Container>
      <div className="title">
        <span {...moveAnimate[0]}>{title}</span>
      </div>
      <div className="main">
        <div className="mainWrapper">
          <div className="main-img">
            <img {...centerAnimate[0]} src={img1} alt="img" />
            <img {...centerAnimate[1]} src={img2} alt="img" />
            <img {...centerAnimate[2]} src={img3} alt="img" />
            <img {...centerAnimate[3]} src={img4} alt="img" />
          </div>
          <div className="main-title">
            <span {...animation[0]}>{sub1}</span>
            <p {...animation[1]} className="main-sub">
              {sub}
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default About4;
